.selected:hover,
.menu a:hover,
#DropdownGridItem:hover,
.ui.mini.compact.labeled.icon.four.item.menu .item:hover,
.ui.mini.compact.labeled.icon.two.item.menu .item:hover {
  cursor: pointer !important;
  background-color: #4b72b2 !important;
  color: white !important;
}

.extra a:hover,
.meta a:hover {
  background-color: transparent !important;
}

.AssetNot {
  width: 27px;
  display: flex !important;
}

.meta a:hover {
  cursor: default !important;
}
#header,
#header .ui.secondary.menu {
  height: 55px;
  margin-bottom: 0;
}

#offlineMode {
  margin-top: 0;
}

.ui.mini.compact.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu {
  cursor: pointer !important;
  color: #4b72b2 !important;
  background-color: white !important;
}

.ui.menu .ui.dropdown .menu .item:hover {
  background: #4b72b2 !important;
  color: white !important;
}

/* .ui.horizontal .list {
  background-color: aqua !important;
} */

#header {
  position: relative !important;
  z-index: 1;
}

.ui.mini.image,
.bars.big.icon,
.ui.dimmer,
.ui.blue.card .content .description .ui.horizontal.list,
.boxButtonsNavbarItem {
  cursor: pointer !important;
}

.three.wide.column {
  z-index: 1;
}

.ui.blue.card {
  margin: 8px !important;
  padding-top: auto;
}

.notification-container {
  top: 90px !important;
}
