.logo .loadingLogo {
  fill: #4b72b2;
  opacity: 1;
}

.logo .loadingLogo2 {
  fill: #fff;
  opacity: 1;
}

.logo .loadingLogo2 .logo-small {
  fill: #fff;
  opacity: 1;
}

.logo-small .loadingLogo {
  fill: #4b72b2;
  opacity: 1;
}

.logo-small .loadingLogo2 {
  fill: #fff;
  opacity: 1;
}

.logo span {
  color: #fff;
  font-size: 10px;
  margin-left: 50px;
}

.logo span:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

.logo-small span {
  color: #fff;
  font-size: 10px;
  margin-left: 50px;
}

.logo-small span:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 0.625em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 0.625em;
  }
}

.logo {
  animation: pulse 0.7s infinite;
  height: 65px;
  margin: 0 auto;
  display: table;
  margin-top: 0px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  margin-bottom: 7.5px;
}

.logo-small {
  animation: pulse 0.7s infinite;
  height: 30px;
  fill: #4b72b2 !important;
  margin: 0 auto;
  display: table;
  margin-top: 0px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  margin-bottom: 7.5px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -webkit-filter: brightness(100%);
  }
  100% {
    -webkit-transform: scale(1.1);
    -webkit-filter: brightness(200%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }
  100% {
    transform: scale(1.1);
    filter: brightness(200%);
  }
}
