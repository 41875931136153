.container-fluid {
  display: flex;
  flex-direction: column;
}

.leaflet-control-attribution {
  display: none;
}

.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible {
  visibility: hidden !important;
}

/* .b24-widget-button-inner-mask {
  position: absolute;
  top: -8px;
  left: -8px;
  height: 50px !important;
  min-width: 40px !important;
  -webkit-width: calc(100% + 16px);
  width: calc(100% + 16px);
  border-radius: 100px;
  background: #4b72b2 !important;
  opacity: 1;
}

.b24-crm-button-icon {
  /*border: 3px red solid;
  height: 18px;
  margin-top: -30px;
}

.b24-widget-button-inner-block {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 37px;
  border-radius: 100px;
  background: #4b72b2 !important;
  box-sizing: border-box;
}

.b24-widget-button-position-bottom-right {
  right: 5px !important;
  bottom: 23px !important;
}

.b24-crm-button-icon .b24-crm-button-icon-active {
  margin-top: -27px !important;
  width: 16px !important;
  height: 16px !important;
}

.b24-widget-button-block {
  width: 34px !important;
  height: 34px !important;
} */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.column {
  margin: 0 !important;
  padding: 0 !important;
}

.botao-up-phyll {
  pointer-events: auto;
  padding: 8px 5px 3px 5px;
  background: white;
  margin-right: 40px;
  margin-top: 10px;
}
.notification {
  margin-top: 1px !important;
}

.dropdown-toggle::after {
  content: none;
}

.nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.container-fluid {
  padding: 0;
}

.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: white;
  height: 150px;
  padding: 0;
  color: blue;
}
.container-fluid {
  width: 100%;
  height: 100%;
  position: fixed;
}
.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#telaCarregamento {
  height: 100%;
  width: 100vw;
  position: absolute;
  z-index: 2999;
  background-color: rgba(0, 20, 50, 0.75);
  opacity: 1;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.logoTelaCarregamento {
  /*border:1px red solid;*/
  margin-top: -50px;
  width: 200px;
  height: 200px;
}

.logoTelaCarregamento {
  height: 100px;
}

.logoTelaCarregamento .loadingLogo {
  fill: #4b72b2;
  opacity: 1;
}

.logoTelaCarregamento .loadingLogo2 {
  fill: #fff;
  opacity: 1;
}

.logoTelaCarregamento span {
  color: #fff;
  font-size: 20px;
  margin-left: 50px;
}

.logoTelaCarregamento .timeout {
  color: #fff;
  font-size: 14px;
  margin-left: 25px;
  padding: 15px;
}

.logoTelaCarregamento span:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.pulse {
  animation: pulse 0.7s infinite;
  height: 100px;
  margin: 0 auto;
  display: table;
  margin-top: 0px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  margin-bottom: 7.5px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -webkit-filter: brightness(100%);
  }
  100% {
    -webkit-transform: scale(1.1);
    -webkit-filter: brightness(200%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }
  100% {
    transform: scale(1.1);
    filter: brightness(200%);
  }
}

/*
#imagemLogoTelaCarregamento{
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }*/

.recharts-legend-wrapper {
  max-height: 48px;
  overflow-y: auto;
  padding-bottom: 8px;
}

.recharts-legend-item-text {
  color: #666 !important;
}

.dataLabelingPusher::-webkit-scrollbar {
  /* oculta a barra de rolagem em navegadores WebKit */
  display: none;
}
