#imgcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: clip;
}

#imgdistort {
  position: absolute;
  top: 0px;
  left: 0px;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}

.corner {
  position: absolute;
  top: -10px;
  left: -10px;
}

#inputimage {
  position: absolute;
  top: -100px;
}

.leaflet-pane .leaflet-overlay-pane img {
  pointer-events: all !important;
}

.leaflet-toolbar-icon {
  box-sizing: initial;
}

.leaflet-toolbar-tip {
  box-sizing: border-box;
}

img.leaflet-image-layer.selected {
  box-shadow: 0px 0px 0px 12px #ffea00;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v8/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

.material-icons,
.material-icons-outlined {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons.red {
  color: #c10d0d;
}

.material-icons.md-18,
.material-icons-outlined.md-18 {
  font-size: 18px;
  line-height: inherit;
}

#imgcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: clip;
}

#imgdistort {
  position: absolute;
  top: 0px;
  left: 0px;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}

.corner {
  position: absolute;
  top: -10px;
  left: -10px;
}

#inputimage {
  position: absolute;
  top: -100px;
}

.leaflet-pane .leaflet-overlay-pane img {
  pointer-events: all !important;
}

.leaflet-toolbar-icon {
  box-sizing: initial;
}

.leaflet-toolbar-tip {
  box-sizing: border-box;
}

img.leaflet-image-layer.selected {
  box-shadow: 0px 0px 0px 12px #ffea00;
}

.l-container {
  background-color: rgb(255, 255, 255, 0.75);
  color: black;
  padding: 0px 10px 6px 10px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.2;
}

.l-container table th {
  font-size: 16px;
  padding-top: 8px;
}

th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

td {
  padding: 0.3rem;
  vertical-align: top;
}

kbd {
  font-weight: 500;
  padding: 0.2rem 0.4rem;
  font-size: 95%;
  color: black;
  background-color: rgb(247, 247, 247);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
  -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  text-shadow: 0 0.5px 0 #fff;
}
