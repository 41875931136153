.rdr-DateInput {
  display: flex;
  flex-direction: column;
  border-left: 1px solid;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  -webkit-box-shadow: 5px 5px 81px rgba(41, 60, 74, 0.18);
  box-shadow: 5px 5px 81px rgba(41, 60, 74, 0.18);
}

.rdr-DateRangePicker {
  padding: 21px;
}

.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}

.rdrMonth {
  width: 300px;
  font-size: 1.2em;
  padding: 0;
}

.rdrStartEdge {
  border-top-left-radius: 1.14em;
  border-bottom-left-radius: 1.14em;
}

.rdrEndEdge {
  border-top-right-radius: 1.14em;
  border-bottom-right-radius: 1.14em;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  border: 0;
  background: rgba(200, 200, 200, 0.3);
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek.rdrInRange {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek.rdrInRange {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdrStartEdge.rdrEndEdge {
  border-radius: 1.14em;
}

.rdrMonthAndYearWrapper {
  padding-bottom: 8px;
  padding-top: 0;
  height: 32px;
}

.rdrDay {
  height: 2.5em;
}

.rdrMonthPicker select,
.rdrYearPicker select {
  background: none;
  font-weight: normal;
  font-size: 16px;
  padding: 0;
}

.rdrMonthsVertical {
  align-items: center;
}

.rdrCalendarWrapper {
  flex: 1;
}

.rdrNextPrevButton {
  background-color: #e7e1ec;
}

.rdrPprevButton i {
  border-right-color: #f9f6fd;
}

.rdrNextButton i {
  border-left-color: #f9f6fd;
}

.rdrDateDisplayItem input {
  margin: 0 !important;
}

.rdr-TimePicker {
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid rgb(226, 219, 232);
}

.rdr-UTCPicker {
  color: #9585a3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin-left: 5px;
}

.btnCalendar {
  margin-left: 40px;
  margin-bottom: 1px;
}
