@import url('~react-leaflet-markercluster/dist/styles.min.css');
.divPopup {
  z-index: 10000;
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 400;
  cursor: pointer;
}
div.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  text-overflow: ellipsis;
}
div.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

div.fenceEye {
  right: 1125px;
  top: 424px;
  margin-top: 0px;
  width: 50px;
  margin-left: 0px;
  left: 9.5px;
  margin-right: 0px;
  display: block;
  z-index: 1100;
  position: absolute;
}

.rc-dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.rc-dropdown-menu-item-selected {
  background-color: #eaf8fe;
  /* // fix chrome render bug */
  transform: translateZ(0);
}

.rc-dropdown-menu-item-group-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.rc-dropdown-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
}

.rc-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
}

.rc-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 7px 10px;
  clear: both;
  font-size: 12px;
  font-weight: normal;
  color: #666666;
  white-space: nowrap;
  cursor: pointer;
}

.checkDiv {
  left: -8px;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.form-control {
  height: 23px;
  bottom: 1px;
}

.valid-feedback,
.invalid-feedback {
  width: 100px;
  margin-left: 2px;
}

.PopupFence {
  height: 102px;
  width: 162px;
}

.divTrash {
  margin-top: -35px;
  margin-right: 5px;
  margin-left: 1px;
}

.batteryPath {
  fill: white;
  stroke: #42913f;
}

.popupBatteryEmptyButton {
  fill: #439a41;
  stroke: #42913f;
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
  width: 17px;
  height: 17px;
  padding-top: 5px;
  margin-left: 3px;
}

.popupBatteryQuarterButton {
  fill: #439a41;
  stroke: #42913f;
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
  width: 17px;
  height: 17px;
  padding-top: 5px;
  margin-left: 3px;
}

.popupBatteryHalfButton {
  fill: #439a41;
  stroke: #42913f;
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
  width: 17px;
  height: 17px;
  padding-top: 5px;
  margin-left: 3px;
}

.popupBatteryThreeQuartersButton {
  fill: #439a41;
  stroke: #42913f;
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
  width: 17px;
  height: 17px;
  padding-top: 5px;
  margin-left: 3px;
}

.popupBatteryFullButton {
  fill: #439a41;
  stroke: #42913f;
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
  width: 17px;
  height: 17px;
  padding-top: 5px;
  margin-left: 3px;
}

.greenFollowButton {
  fill: green !important;
  cursor: pointer;
}

.leaflet-top {
  top: 40px;
}

.divFenceButton {
  margin-bottom: 50px;
}

.divCercaButton {
  margin-top: 13px;
  margin-bottom: -15px;
}

.divSvg {
  margin-bottom: 5px;
  margin-left: 0px;
}

.popupButtons3 {
  fill: black;
  width: 15px;
  height: 15px;
  margin-left: 0px;
}

.inputFencePopup {
  height: 28px;
  width: 101px;
  margin-left: 0px;
}

.popupButtons2 {
  fill: black;
  width: 17px;
  height: 17px;
  margin-left: 3px;
}

.popupButtons {
  fill: black;
  width: 17px;
  height: 17px;
  margin-left: 3px;
}

.showFields {
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkBoxPopup {
  margin-top: 3px;
  margin-bottom: 4px;
}

.info {
  padding-left: 15px;
}

.hBattery {
  padding-right: 15px;
  padding-left: 34px;
}

.fencePopup {
  height: 89px;
  width: 144px;
}

#followBtn {
  width: 54px;
  padding-bottom: 0px;
  padding-left: 6px;
  padding-top: 5px;
  margin-bottom: 2px;
  margin-top: 15px;
}

#fenceBtn {
  width: 54px;
  padding-bottom: 1px;
  margin-bottom: 5px;
}

.btn-cercaColor {
  background-color: #38718f;
  border-color: #38718f;
}

.button-expand {
  padding: 0;
  margin-top: 13px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

b {
  margin-left: 5px;
  text-transform: capitalize;
}

.leaflet-container {
  height: 47vw;
}

.popupAtivo {
  padding-right: 3px;
}

.popupheader {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 2px;
  margin-left: 0px;
  /* right:-45px;
  left:45px */
}

.divBateria {
  right: -15px;
}

.popup_title {
  /* border-bottom: 1px solid #c3c3c3; */
  margin-bottom: 10px;
  padding-bottom: 3px;
}

.popup_title:after {
  content: '';
  /* This is necessary for the pseudo element to work. */
  display: block;
  /* This will put the pseudo element on its own line. */
  margin: 0 auto;
  /* This will center the border. */
  width: 98%;
  /* Change this to whatever width you want. */
  padding-top: 3px;
  /* This creates some space between the element and the border. */
  border-bottom: 1px solid #c3c3c3;
  /* This creates the border. Replace black with whatever color you want. */
}

.h5 {
  /* border-bottom: 1px solid #c3c3c3; */
  font-size: 14px;
  letter-spacing: 0.5px;
  /* padding-bottom:4px;
  margin-bottom: 2rem; */
}

.popups div p {
  margin: 8px 0;
}

.sc-gPWkxV.eubXdL,
.pushable.sc-jVODtj.iVJhpy {
  flex: 3 3 auto;
}

#map {
  position: static;
  /* min-height: 390px;
  min-width: 768px; */
  overflow: visible; /* it might fix map gray tiles issue: https://github.com/google-map-react/google-map-react/issues/390#issuecomment-311776191 */
  height: 100%;
}

#slide_footer {
  width: 20vw;
  z-index: 0;
  position: absolute;
  margin-left: 40vw;
  padding: 0;
  padding-top: 0;
}

#tempo_final {
  background-color: #cecece;
  padding: 0 2vw 0 2vw;
  width: 20vw;
  font-size: 2vh;
  text-align: center;
  align-self: center;
}

#tempo_inicial {
  background-color: white;
  padding: 0 2vw 0 2vw;
  width: 20vw;
  font-size: 2vh;
  text-align: center;
  align-self: center;
}

.leaflet-popup-content-wrapper {
  z-index: 410;
}

#logo_empresa {
  width: 20vw;
  z-index: 401;
  position: absolute;
  margin-left: 40vw;
  padding: 0;
  padding-top: 0;
}

#logo_empresa img {
  max-height: 20vw;
  max-width: 15vw;
  margin-left: 2.5vw;
}

ul.js-measuretasks li {
  margin: 0 !important;
  display: inline-flex;
}

ul.js-measuretasks li a.js-cancel {
  float: right;
  margin-right: 20px;
}

p.js-starthelp {
  width: 209px;
  text-align: center;
}

.js-measuringprompt h3 {
  text-align: center;
  width: 209px;
}

#timeSliderMinimum {
  width: 9vw;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #efefef;
  border-radius: 10px;
  padding: 0.5vw;
  float: left;
  text-align: center;
  border: 2px solid lightblue;
}

#timeSliderMaximum {
  width: 9vw;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #efefef;
  border-radius: 10px;
  padding: 0.5vw;
  float: right;
  text-align: center;
  border: 2px solid lightgreen;
}

.rc-slider-handle {
  cursor: w-resize !important;
}

.leaflet-control-layers {
  margin-bottom: 50px !important;
}

.fenceLeftButton {
  margin-left: 1px;
  height: 26px;
  width: 26px;
  padding: 2px;
  background-color: white;
}

.leaflet-control-layers-toggle {
  width: 26px !important;
  height: 26px !important;
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-size: 18px 18px !important;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px !important;
  height: 30px !important;
}

.sBattery {
  /* left: 1px; */
  right: 20px;
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  top: 10px;
  line-height: 9px;
  font-size: 12px;
}

.iBattery {
  padding-top: 0 !important;
  transform: rotate(270deg);
  width: 15px;
  height: 15px;
}

.sensorStatus {
  padding-top: 0 !important;
  width: 13px;
  height: 13px;
}

.popupBatteryQuarterButton {
  fill: red;
  stroke: black;
}

.popupBatteryHalfButton {
  fill: yellow;
  stroke: white;
}

.popupBatteryEmptyButton {
  stroke: black;
}

.localizacao {
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkBoxContainer {
  margin-top: -8px;
}

.popupBateriaInfo {
  right: 0px;
  left: 43px;
  margin-right: 0px;
  margin-left: 0px;
  bottom: 4px;
}

.svgBateria {
  right: 8px;
  top: -6px;
}

.popupDivSpanBateria {
  right: 1px;
}

.popupIconsStatus {
  left: 21px;
  padding-left: 0px;
  padding-right: 0px;
}

.nomeAtivo {
  top: 3px;
}

/* .leaflet-popup-content {
  width: 245px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 13px;
  margin-right: 13px;
} */

.on {
  fill: forestgreen;
}

.off {
  fill: #707070;
}

.UltimaVezVisto {
  margin-left: 170px;
}

.spanUltimaVezVisto {
  font-size: 9px;
}

.background-fill-carregando {
  fill: white;
}

.bateria-carregando {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 23px;
}

.statusSensor {
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 15px;
  left: 15px;
}

.svg-bateria-carregando {
  fill: forestgreen;
}

.alerta {
  fill: gold;
}

.redGps {
  fill: darkred;
}

.btnHist {
  margin: 1px 20px;
  width: 110px;
}

.leaflet-draw-draw-polyline {
  margin-bottom: 13px;
}

.leaflet-draw-draw-marker {
  margin-top: 13px;
}

.dropdown-hist-button {
  margin-right: 3px;
  background-color: white;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
}

.csv-routes {
  margin-top: -30px;
  margin-left: 120px;
}
